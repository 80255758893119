<template>
  <v-row class="pt-10 report-row font-family-raleway-medium">
    <v-col lg="12" md="12" xl="12">
      <v-card class="report-card" style="text-align: left !important">
        <div class="d-flex align-center justify-space-between">
          <h2 class="font-family-raleway-bold">REPORTS</h2>
          <div class="align-center d-flex">
            <v-btn
              height="48"
              width="48"
              class="box-shadow-none"
              style="background-color: #ffffff"
            >
              <img :src="searchIcon" />
            </v-btn>
            <v-btn class="down-btn mx-5" height="48" width="48">
              <img :src="downIcon" height="20" width="20" />
            </v-btn>
            <v-btn class="down-btn" height="48" width="48">
              <img :src="printIcon" height="20" width="20" />
            </v-btn>
          </div>
        </div>
        <v-data-table
          :items="desserts"
          class="elevation-1 table mt-10"
          fixed-header
          hide-default-footer
        >
          <template v-slot:header>
            <thead style="border-bottom: 1px solid black !important">
              <tr class="font-family-raleway-medium color-gray">
                <td style="font-size: 16px">Generation date</td>
                <td style="font-size: 16px">Campaigns</td>
                <td style="font-size: 16px">Cost</td>
                <td style="font-size: 16px">Actions</td>
              </tr>
            </thead>
          </template>
          <template v-slot:body>
            <tbody>
            </tbody>
          </template>
        </v-data-table>
        <div class="text-center my-4">
          <span>No data</span>
        </div>
        <v-divider />
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import searchIcon from "../../assets/img/searchIcon.svg";
import downIcon from "../../assets/img/down.svg";
import printIcon from "../../assets/img/print.svg";

export default {
  name: "Report",
  data() {
    return {
      searchIcon,
      printIcon,
      downIcon,
      page: 1,
      headers: [
        {
          text: "Generation date",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Campaigns", value: "campaign" },
        { text: "Cost", value: "cost" },
        { text: "Actions", value: "actions" },
      ],
      desserts: [],
    };
  },
};
</script>

<style scoped lang="scss">
.report-card {
  padding: 40px;
  box-shadow: none !important;
  border-radius: 12px;
  @media screen and (max-width: 600px) {
    padding: 20px 15px;
  }
  @media screen and (max-width: 450px) {
    padding: 20px 10px;
  }
}

.table {
  box-shadow: none !important;
  @media screen and (max-width: 800px) {
    display: none;
  }

  thead {
    tr td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.table-row {
  height: 80px !important;

  td {
    border: none !important;
  }
}

.down-btn {
  background-color: #f5f9fc !important;
  border-radius: 12px !important;
  box-shadow: none;
}

.tbody:hover {
  background-color: #f6f9fc !important;
}
</style>
